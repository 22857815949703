function createAsyncQueue() {
  const queue = [];
  let queuePromise = null;
  return {
    add(...tasks) {
      queue.push(...tasks);
      return this;
    },
    async run(input) {
      if (queuePromise) {
        return queuePromise;
      }
      let resolvePromise;
      let rejectPromise;
      queuePromise = new Promise((resolve, reject) => {
        resolvePromise = resolve;
        rejectPromise = reject;
      });
      let currentValue = input;
      try {
        while (queue.length > 0) {
          const task = queue.shift();
          currentValue = await task(currentValue);
        }
        resolvePromise(currentValue);
        return currentValue;
      } catch (e) {
        rejectPromise(e);
        throw e;
      } finally {
        queuePromise = null;
      }
    },
    async waitForFinish() {
      if (queuePromise) {
        await queuePromise;
      }
    }
  };
}
export { createAsyncQueue };
